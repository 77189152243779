import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Button } from 'components/Button';
import { Link } from 'components/Link';
import { Typography } from 'components/Typography';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { DropoverContent, DropoverLabel, DropoverInputLabel, InlineDropover, PortalDropover } from '../index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="compositions/Dropover" title="Dropover" mdxType="PageTitle" />
    <h2 {...{
      "id": "inline-dropover"
    }}>{`Inline dropover`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <InlineDropover\n    focusable\n    label={\n      <DropoverLabel>\n        <Typography\n          color=\"primary\"\n          size=\"large\"\n          variants=\"line-height-normal\"\n          weight=\"bold\"\n        >\n          Hello world\n        </Typography>\n      </DropoverLabel>\n    }\n  >\n    <DropoverContent>\n      <Typography size=\"medium\" variants=\"line-height-comfy\">\n        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eros\n        neque, ullamcorper at erat quis, eleifend gravida{\' \'}\n        <Link href=\"#\">dolor</Link>.\n      </Typography>\n    </DropoverContent>\n  </InlineDropover>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Link,
      Typography,
      PageTitle,
      ThemeWrapper,
      DropoverContent,
      DropoverLabel,
      DropoverInputLabel,
      InlineDropover,
      PortalDropover,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <InlineDropover focusable label={<DropoverLabel mdxType="DropoverLabel">
          <Typography color="primary" size="large" variants="line-height-normal" weight="bold" mdxType="Typography">
            Hello world
          </Typography>
        </DropoverLabel>} mdxType="InlineDropover">
      <DropoverContent mdxType="DropoverContent">
        <Typography size="medium" variants="line-height-comfy" mdxType="Typography">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eros neque, ullamcorper at erat quis,
          eleifend gravida <Link href="#" mdxType="Link">dolor</Link>.
        </Typography>
      </DropoverContent>
    </InlineDropover>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "inline-dropover-aligned-right"
    }}>{`Inline dropover aligned right`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <InlineDropover\n    focusable\n    align=\"right\"\n    label={\n      <DropoverLabel>\n        <Typography\n          color=\"primary\"\n          size=\"large\"\n          variants=\"line-height-normal\"\n          weight=\"bold\"\n        >\n          Hello world\n        </Typography>\n      </DropoverLabel>\n    }\n    width={400}\n  >\n    <DropoverContent>\n      <Typography size=\"medium\" variants=\"line-height-comfy\">\n        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eros\n        neque, ullamcorper at erat quis, eleifend gravida{\' \'}\n        <Link href=\"#\">dolor</Link>.\n      </Typography>\n    </DropoverContent>\n  </InlineDropover>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Link,
      Typography,
      PageTitle,
      ThemeWrapper,
      DropoverContent,
      DropoverLabel,
      DropoverInputLabel,
      InlineDropover,
      PortalDropover,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <InlineDropover focusable align="right" label={<DropoverLabel mdxType="DropoverLabel">
          <Typography color="primary" size="large" variants="line-height-normal" weight="bold" mdxType="Typography">
            Hello world
          </Typography>
        </DropoverLabel>} width={400} mdxType="InlineDropover">
      <DropoverContent mdxType="DropoverContent">
        <Typography size="medium" variants="line-height-comfy" mdxType="Typography">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eros neque, ullamcorper at erat quis,
          eleifend gravida <Link href="#" mdxType="Link">dolor</Link>.
        </Typography>
      </DropoverContent>
    </InlineDropover>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "inline-dropover-no-triangle"
    }}>{`Inline dropover, no triangle`}</h2>
    <Playground __position={3} __code={'<ThemeWrapper>\n  <InlineDropover\n    focusable\n    label={\n      <DropoverLabel noTriangle>\n        <Typography\n          color=\"primary\"\n          size=\"large\"\n          variants=\"line-height-normal\"\n          weight=\"bold\"\n        >\n          Hello world\n        </Typography>\n      </DropoverLabel>\n    }\n  >\n    <DropoverContent>\n      <Typography size=\"medium\" variants=\"line-height-comfy\">\n        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eros\n        neque, ullamcorper at erat quis, eleifend gravida{\' \'}\n        <Link href=\"#\">dolor</Link>.\n      </Typography>\n    </DropoverContent>\n  </InlineDropover>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Link,
      Typography,
      PageTitle,
      ThemeWrapper,
      DropoverContent,
      DropoverLabel,
      DropoverInputLabel,
      InlineDropover,
      PortalDropover,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <InlineDropover focusable label={<DropoverLabel noTriangle mdxType="DropoverLabel">
          <Typography color="primary" size="large" variants="line-height-normal" weight="bold" mdxType="Typography">
            Hello world
          </Typography>
        </DropoverLabel>} mdxType="InlineDropover">
      <DropoverContent mdxType="DropoverContent">
        <Typography size="medium" variants="line-height-comfy" mdxType="Typography">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eros neque, ullamcorper at erat quis,
          eleifend gravida <Link href="#" mdxType="Link">dolor</Link>.
        </Typography>
      </DropoverContent>
    </InlineDropover>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "portal-dropover"
    }}>{`Portal dropover`}</h2>
    <Playground __position={4} __code={'<ThemeWrapper>\n  <PortalDropover\n    label={\n      <DropoverLabel>\n        <Typography\n          color=\"primary\"\n          size=\"large\"\n          variants=\"line-height-normal\"\n          weight=\"bold\"\n        >\n          Hello world\n        </Typography>\n      </DropoverLabel>\n    }\n    portal=\"absolute\"\n  >\n    <DropoverContent>\n      <Typography size=\"medium\" variants=\"line-height-comfy\">\n        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eros\n        neque, ullamcorper at erat quis, eleifend gravida dolor.\n      </Typography>\n    </DropoverContent>\n  </PortalDropover>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Link,
      Typography,
      PageTitle,
      ThemeWrapper,
      DropoverContent,
      DropoverLabel,
      DropoverInputLabel,
      InlineDropover,
      PortalDropover,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <PortalDropover label={<DropoverLabel mdxType="DropoverLabel">
          <Typography color="primary" size="large" variants="line-height-normal" weight="bold" mdxType="Typography">
            Hello world
          </Typography>
        </DropoverLabel>} portal="absolute" mdxType="PortalDropover">
      <DropoverContent mdxType="DropoverContent">
        <Typography size="medium" variants="line-height-comfy" mdxType="Typography">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eros neque, ullamcorper at erat quis,
          eleifend gravida dolor.
        </Typography>
      </DropoverContent>
    </PortalDropover>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "portal-dropover-aligned-right"
    }}>{`Portal dropover aligned right`}</h2>
    <Playground __position={5} __code={'<ThemeWrapper>\n  <PortalDropover\n    align=\"right\"\n    label={\n      <DropoverLabel>\n        <Typography\n          color=\"primary\"\n          size=\"large\"\n          variants=\"line-height-normal\"\n          weight=\"bold\"\n        >\n          Hello world\n        </Typography>\n      </DropoverLabel>\n    }\n    portal=\"absolute\"\n    width={400}\n  >\n    <DropoverContent>\n      <Typography size=\"medium\" variants=\"line-height-comfy\">\n        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eros\n        neque, ullamcorper at erat quis, eleifend gravida dolor.\n      </Typography>\n    </DropoverContent>\n  </PortalDropover>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Link,
      Typography,
      PageTitle,
      ThemeWrapper,
      DropoverContent,
      DropoverLabel,
      DropoverInputLabel,
      InlineDropover,
      PortalDropover,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <PortalDropover align="right" label={<DropoverLabel mdxType="DropoverLabel">
          <Typography color="primary" size="large" variants="line-height-normal" weight="bold" mdxType="Typography">
            Hello world
          </Typography>
        </DropoverLabel>} portal="absolute" width={400} mdxType="PortalDropover">
      <DropoverContent mdxType="DropoverContent">
        <Typography size="medium" variants="line-height-comfy" mdxType="Typography">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eros neque, ullamcorper at erat quis,
          eleifend gravida dolor.
        </Typography>
      </DropoverContent>
    </PortalDropover>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "portal-dropover-no-triangle"
    }}>{`Portal dropover, no triangle`}</h2>
    <Playground __position={6} __code={'<ThemeWrapper>\n  <PortalDropover\n    label={\n      <DropoverLabel noTriangle>\n        <Typography\n          color=\"primary\"\n          size=\"large\"\n          variants=\"line-height-normal\"\n          weight=\"bold\"\n        >\n          Hello world\n        </Typography>\n      </DropoverLabel>\n    }\n    portal=\"absolute\"\n  >\n    <DropoverContent>\n      <Typography size=\"medium\" variants=\"line-height-comfy\">\n        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eros\n        neque, ullamcorper at erat quis, eleifend gravida dolor.\n      </Typography>\n    </DropoverContent>\n  </PortalDropover>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Link,
      Typography,
      PageTitle,
      ThemeWrapper,
      DropoverContent,
      DropoverLabel,
      DropoverInputLabel,
      InlineDropover,
      PortalDropover,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <PortalDropover label={<DropoverLabel noTriangle mdxType="DropoverLabel">
          <Typography color="primary" size="large" variants="line-height-normal" weight="bold" mdxType="Typography">
            Hello world
          </Typography>
        </DropoverLabel>} portal="absolute" mdxType="PortalDropover">
      <DropoverContent mdxType="DropoverContent">
        <Typography size="medium" variants="line-height-comfy" mdxType="Typography">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eros neque, ullamcorper at erat quis,
          eleifend gravida dolor.
        </Typography>
      </DropoverContent>
    </PortalDropover>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "portal-dropover-with-close-button"
    }}>{`Portal dropover with close button`}</h2>
    <Playground __position={7} __code={'<ThemeWrapper>\n  <PortalDropover\n    focusable\n    label={\n      <DropoverLabel>\n        <Typography\n          color=\"primary\"\n          size=\"large\"\n          variants=\"line-height-normal\"\n          weight=\"bold\"\n        >\n          Hello world\n        </Typography>\n      </DropoverLabel>\n    }\n    portal=\"absolute\"\n    renderChildren={({ close, focusRef }) => (\n      <DropoverContent>\n        <Typography size=\"medium\" variants=\"line-height-comfy\">\n          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque\n          eros neque, ullamcorper at erat quis, eleifend gravida\n          <Typography variants=\"space-before\">\n            <Button\n              color=\"primary\"\n              onClick={() => close()}\n              ref={focusRef}\n              weight=\"inline\"\n            >\n              close\n            </Button>\n          </Typography>\n          .\n        </Typography>\n      </DropoverContent>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Link,
      Typography,
      PageTitle,
      ThemeWrapper,
      DropoverContent,
      DropoverLabel,
      DropoverInputLabel,
      InlineDropover,
      PortalDropover,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <PortalDropover focusable label={<DropoverLabel mdxType="DropoverLabel">
          <Typography color="primary" size="large" variants="line-height-normal" weight="bold" mdxType="Typography">
            Hello world
          </Typography>
        </DropoverLabel>} portal="absolute" renderChildren={({
          close,
          focusRef
        }) => <DropoverContent mdxType="DropoverContent">
          <Typography size="medium" variants="line-height-comfy" mdxType="Typography">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eros neque, ullamcorper at erat quis,
            eleifend gravida
            <Typography variants="space-before" mdxType="Typography">
              <Button color="primary" onClick={() => close()} ref={focusRef} weight="inline" mdxType="Button">
                close
              </Button>
            </Typography>.
          </Typography>
        </DropoverContent>} mdxType="PortalDropover" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "portal-dropover-with-form-label"
    }}>{`Portal dropover with form label`}</h2>
    <Playground __position={8} __code={'<ThemeWrapper>\n  <PortalDropover\n    passiveLabel\n    align=\"right\"\n    label={\n      <DropoverInputLabel>\n        <input type=\"text\" />\n      </DropoverInputLabel>\n    }\n    portal=\"absolute\"\n    renderChildren={({ close }) => (\n      <DropoverContent>\n        <Typography size=\"medium\" variants=\"line-height-comfy\">\n          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque\n          eros neque, ullamcorper at erat quis, eleifend gravida\n          <Typography variants=\"space-before\">\n            <Button color=\"primary\" onClick={() => close()} weight=\"inline\">\n              close\n            </Button>\n          </Typography>\n          .\n        </Typography>\n      </DropoverContent>\n    )}\n    width={400}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Link,
      Typography,
      PageTitle,
      ThemeWrapper,
      DropoverContent,
      DropoverLabel,
      DropoverInputLabel,
      InlineDropover,
      PortalDropover,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <PortalDropover passiveLabel align="right" label={<DropoverInputLabel mdxType="DropoverInputLabel">
          <input type="text" />
        </DropoverInputLabel>} portal="absolute" renderChildren={({
          close
        }) => <DropoverContent mdxType="DropoverContent">
          <Typography size="medium" variants="line-height-comfy" mdxType="Typography">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eros neque, ullamcorper at erat quis,
            eleifend gravida
            <Typography variants="space-before" mdxType="Typography">
              <Button color="primary" onClick={() => close()} weight="inline" mdxType="Button">
                close
              </Button>
            </Typography>.
          </Typography>
        </DropoverContent>} width={400} mdxType="PortalDropover" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "contrast-dropover"
    }}>{`Contrast dropover`}</h2>
    <Playground __position={9} __code={'<ThemeWrapper contrast>\n  <InlineDropover\n    label={\n      <DropoverLabel contrast>\n        <Typography\n          color=\"contrast\"\n          size=\"medium\"\n          variants=\"line-height-comfy\"\n          weight=\"bold\"\n        >\n          Hello world\n        </Typography>\n      </DropoverLabel>\n    }\n  >\n    <DropoverContent contrast>\n      <Typography size=\"medium\" variants=\"line-height-comfy\">\n        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eros\n        neque, ullamcorper at erat quis, eleifend gravida dolor.\n      </Typography>\n    </DropoverContent>\n  </InlineDropover>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Link,
      Typography,
      PageTitle,
      ThemeWrapper,
      DropoverContent,
      DropoverLabel,
      DropoverInputLabel,
      InlineDropover,
      PortalDropover,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper contrast mdxType="ThemeWrapper">
    <InlineDropover label={<DropoverLabel contrast mdxType="DropoverLabel">
          <Typography color="contrast" size="medium" variants="line-height-comfy" weight="bold" mdxType="Typography">
            Hello world
          </Typography>
        </DropoverLabel>} mdxType="InlineDropover">
      <DropoverContent contrast mdxType="DropoverContent">
        <Typography size="medium" variants="line-height-comfy" mdxType="Typography">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eros neque, ullamcorper at erat quis,
          eleifend gravida dolor.
        </Typography>
      </DropoverContent>
    </InlineDropover>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <h3 {...{
      "id": "inlinedropover"
    }}>{`InlineDropover`}</h3>
    <Props of={InlineDropover} mdxType="Props" />
    <h3 {...{
      "id": "portaldropover"
    }}>{`PortalDropover`}</h3>
    <Props of={PortalDropover} mdxType="Props" />
    <h3 {...{
      "id": "dropovercontent"
    }}>{`DropoverContent`}</h3>
    <Props of={DropoverContent} mdxType="Props" />
    <h3 {...{
      "id": "dropoverlabel"
    }}>{`DropoverLabel`}</h3>
    <Props of={DropoverLabel} mdxType="Props" />
    <h3 {...{
      "id": "dropoverinputlabel"
    }}>{`DropoverInputLabel`}</h3>
    <Props of={DropoverInputLabel} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      